import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { DefaultLayout } from "../layouts"
import { HeadingLine, Posts, SEO, TwoColumns } from "../components"
import { MemberInterface } from "../typings/member.interface"
import { PostNodes, MarkdownPostInterface } from "../typings/post.interface"
import { PostFormat } from "../lib/post-format"

type MemberDetailProps = {
    data: {
        membersYaml: MemberInterface
        posts: PostNodes
    }
}

const MemberTemplate = (props: MemberDetailProps) => {
    const member = props.data.membersYaml
    const formattedPosts: MarkdownPostInterface[] = useMemo(
        () => PostFormat(props.data.posts.nodes),
        [props.data.posts.nodes]
    )
    return (
        <>
            <SEO title={member.username} />
            <DefaultLayout>
                <h1 className="font-inconsolata text-5xl leading-tight md:leading-normal">
                    {member.name}
                </h1>
                <p className="font-inconsolata text-purple">
                    {member.username}
                </p>

                <TwoColumns title="Role" value={member.role} />
                <TwoColumns
                    title="Specialists"
                    value={member.specialists}
                />

                <p className="my-5 font-inconsolata">{member.bio}</p>

                <HeadingLine>My Posts</HeadingLine>
                {formattedPosts.length > 0 ? (
                    <Posts data={formattedPosts} />
                ) : (
                    <div className="text-center text-lg">
                        Member ini belum memposting apapun. 😥
                    </div>
                )}
            </DefaultLayout>
        </>
    )
}

export const MemberQuery = graphql`
    query MemberQuery($username: String!) {
        membersYaml(username: { eq: $username }) {
            id
            name
            role
            specialists
            username
            bio
        }
        posts: allMarkdownRemark(
            filter: {
                frontmatter: { draft: { eq: false } }
                fields: { detail: { author: { eq: $username } } }
            }
            limit: 100
        ) {
            nodes {
                frontmatter {
                    title
                    slug
                    date(formatString: "DD MMMM YYYY", locale: "id-ID")
                }
            }
        }
    }
`

export default MemberTemplate
